import * as React from "react"
import Timeline from "../components/Timeline"
import TimelineItem from "../components/TimelineItem"
import TimelineSection from "../components/TimelineSection"
import '../styles.scss'

// styles
const pageStyles = {
  color: "#232129",
  padding: 40,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  margin: '0 auto',
  maxWidth: 1000,
}
const mainHeadingStyles = {
  marginTop: 0,
  marginBottom: 20,
  fontSize: '1.8rem',
}
const headingStyles = {
  marginTop: 65,
  marginBottom: 20,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const doclistStyles = {
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: 300,
  fontSize: 24,
  maxWidth: 560,
  marginBottom: 30,
}

const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  display: `inline-block`,
  marginBottom: 24,
  marginRight: 12,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLinks = [
  {
    text: "TypeScript Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/",
    color: "#8954A8",
  },
  {
    text: "GraphQL Typegen Documentation",
    url: "https://www.gatsbyjs.com/docs/how-to/local-development/graphql-typegen/",
    color: "#8954A8",
  }
]

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative" as "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>CV | David Prandzioch</title>

      <div className="overlay"></div>

      <h1 style={mainHeadingStyles}>
        David Prandzioch <span style={headingAccentStyles}>—  software developer</span>
      </h1>

      <div className="about">
        <b>David Prandzioch (32 years old, born 23.06.1990 in Kaiserslautern, Germany)</b><br />
        <b>Languages:</b> German (native), English (C1), Spanish (A1)<br />
        <b>Passion:</b> Technology, Animal welfare, Music (Guitar, Recording), Electric vehicles<br />
        <b>Technical skills:</b> Frontend development (HTML, CSS, JS, Angular, React, Responsive design, PWA etc.), Backend development (NodeJS, PHP, Python, a bit Ruby & Go), Databases (MySQL, Postgres, MongoDB), Operations (Linux, FreeBSD, HTTP, DNS, E-Mail, etc.), Testing (TDD, Jasmine, Cypress etc.) and more but it's probably easier to have a chat :-)<br />

        <blockquote>
          I love to build products to empower people and to enrich their lives.
        </blockquote>
      </div>

      <h2 style={headingStyles}>
        Contact <span style={headingAccentStyles}>—  in case you want to reach me</span>
      </h2>
      <p>
        <b>GitHub:</b> <a href="https://www.github.com/dprandzioch" target="_blank" style={linkStyle}>dprandzioch</a><br />
        <b>LinkedIn:</b> <a href="https://www.linkedin.com/in/david-prandzioch-0318b5231" target="_blank" style={linkStyle}>David Prandzioch</a><br />
        <b>Twitter:</b> <a href="https://twitter.com/__davd" target="_blank" style={linkStyle}>__davd</a><br />
        <b>Mail:</b> <a href="mailto:cv@davd.io" style={linkStyle}>cv@davd.io</a>
      </p>

      <h2 style={headingStyles}>
        Timeline <span style={headingAccentStyles}>—  what happened so far</span>
      </h2>
      <Timeline>
        <TimelineSection title="Professional experience">
          <TimelineItem title="Frontend Team Lead" company="ALZURA AG, Remote / Home office" from="2020">
            <p>
              At ALZURA AG, I work as a team lead of the frontend development team for ALZURA Shop, a SaaS online-shop solution with integrated CMS.
              I take care of the long-term technological strategy of the frontend applications and coordinate multiple long-term migration projects to ensure
              that our application runs on state-of-the-art technologies for years to come. Besides that, we constantly improve code quality and reusability
              by creating encapsulated components, building cohesive APIs and introducing test-driven development.
            </p>
            <p>
              Technologies: Angular, AngularJS, NodeJS, PHP, MySQL, MongoDB, RabbitMQ, Docker, Kubernetes, Karma/Jasmine, Cypress
            </p>
          </TimelineItem>
          <TimelineItem title="Self-employed" company="petoffice" from="2019">
            <p>
              In 2017, I started development of a software that aims to simplify the work of animal charities in Germany.
              petoffice set out to give them a comprehensive way to manage the whole flow of picking up strays, taking care for them
              and finding new homes within and across country borders, including foster home management, application forms, contract generation,
              transport planning and much more.
            </p>
            <p>
              The application was quickly adopted by multiple organizations and today manages multiple 100k data sets. The one-stop shop SaaS
              solution can be rented on a monthly basis and is fully managed, developed and supported by me. Built upon a modern, fully containerized
              service-oriented architecture written in NodeJS and Angular, petoffice is a project that allows me to learn about new technologies
              and gain more understanding about the business-side of things.
            </p>
            <p>
              Technologies: Angular, React, GraphQL, NodeJS, NestJS, MongoDB, Elasticsearch, Redis, Docker
            </p>
            <p>
              URL: <a href="https://www.petoffice.eu/" target="_blank" style={linkStyle}>https://www.petoffice.eu/</a>
            </p>
          </TimelineItem>
          <TimelineItem title="Senior Frontend Developer" company="ALZURA AG, Kaiserslautern" from="2016" to="2020">
            <p>
              Initially, I worked at ALZURA AG as a Senior Frontend Developer. Project details and technologies can be found above.
            </p>
          </TimelineItem>
          <TimelineItem title="Head of Software Development" company="omeco GmbH, Kaiserslautern" from="2014" to="2016">
            <p>
            At omeco GmbH, I worked as the Head of Software Development, planning and implementing new functionality for our core software product as well
            as customer-specific extensions, which was called omeco Webshop, an on-premise or hosted e-commerce application. Later, we migrated to Shopware
            as a core software, where I became certified Advanced Shopware Developer. I was overseeing the software and project development team of roughly 15 people.
            </p>
            <p>
              Technologies: PHP, Zend Framework, Vanilla JS, jQuery, ExtJS, Java, JSP, Ruby
            </p>
          </TimelineItem>
          <TimelineItem title="Software Developer" company="omeco GmbH, Kaiserslautern" from="2012" to="2014">
            <p>
              Before working as a Head of Software Development at omeco GmbH, I was working there as a Software Developer, working on both the core e-commerce
              application and customer projects. Besides technical advancements, I gained a lot of experience in direct customer contact and collaborating to
              achieve great results.
            </p>
            <p>
              Technologies: PHP, Zend Framework, Vanilla JS, jQuery, Java, JSP
            </p>
          </TimelineItem>
          <TimelineItem title="Trainee Software Development" company="omeco GmbH, Kaiserslautern" from="2009" to="2012">
            <p>
              I started my apprenticeship to become a Software Developer at omeco GmbH, job description and technologies are pretty much the same as described above.
            </p>
          </TimelineItem>
          <TimelineItem title="Mini Jobber Web Development" company="EST Engineering Systems Technologies, Kaiserslautern" from="2006" to="2008"></TimelineItem>
        </TimelineSection>
        <TimelineSection title="Education">
         <TimelineItem title="Fachinformatiker Anwendungsentwicklung" company="Berufsbildende Schule I Kaiserslautern" from="2009" to="2012"></TimelineItem> 
         <TimelineItem title="Staatlich anerkannter Assistent für Informatik und Automatisierungstechnik" company="Berufsbildende Schule I Kaiserslautern" from="2007" to="2009"></TimelineItem> 
        </TimelineSection>
      </Timeline>

      <h2 style={headingStyles}>
        Projects <span style={headingAccentStyles}>—  wanna learn more?</span>
      </h2>

      <div className="projects">
        <div className="project">
          <h3>davd.io</h3>
          <p>
            My personal blog, few development related topics but very technical. Mostly about server technologies, Linux and FreeBSD.
          </p>
          <p>
            URL: <a href="https://www.davd.io/" target="_blank" style={linkStyle}>https://www.davd.io/</a>
          </p>
        </div>
        <div className="project">
          <h3>petoffice</h3>
          <p>
            Passion project with the goal to empower animal welfare by reducing the amount of management and paperwork they have to do.
          </p>
          <p>
            URL: <a href="https://www.petoffice.eu/" target="_blank" style={linkStyle}>https://www.petoffice.eu/</a>
          </p>
        </div>
        <div className="project">
          <h3>docker-ddns</h3>
          <p>
            Little tool I needed a while back to update my DNS configuration with my dynamic home IP. Using Bind9, Go and Docker.
          </p>
          <p>
            URL: <a href="https://github.com/dprandzioch/docker-ddns" target="_blank" style={linkStyle}>https://github.com/dprandzioch/docker-ddns</a>
          </p>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
