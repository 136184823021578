import * as React from "react"

const Timeline = (props: React.PropsWithChildren) => {
  return (
    <div>{props.children}</div>
  );
};

export default Timeline;

