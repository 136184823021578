import * as React from "react"
import './TimelineSection.scss'

const TimelineSection = (props: React.PropsWithChildren<{title: string}>) => {
  return (
    <div className="timeline-section">
      <div className="timeline-section__title">{props.title}</div>
      <div className="timeline-section__items">{props.children}</div>
    </div>
  );
};

export default TimelineSection;

