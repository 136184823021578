import * as React from "react"
import './TimelineItem.scss'

const TimelineItem = (props: React.PropsWithChildren<{title: string, company: string, from: string, to?: string}>) => {
  return (
    <div className="timeline-item">
      <div className="timeline-item__title">{props.title}</div>
      <div className="timeline-item__company">{props.company}</div>
      <div className="timeline-item__timespan">{!props.to ? 'since ' : ''}{props.from}{props.to ? ` − ${props.to}` : ''}</div>
      <div className="timeline-item__description">{props.children}</div>
    </div>
  );
};

export default TimelineItem;

